<template>
    <div data-scroll :data-scroll-id="name" class="rout-main-layout">
        <component :is="layout" :data="data" :config="config" :name="name"></component>
    </div>
</template>

<script>
import first from 'lodash/first'

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        config: {
            type: Object,
            default: () => {
            }
        },
        name: {
            type: String,
            default: ''
        },
    },
    computed: {
        layout() {
            let name = first(this.config?.conf)

            return () => import( `@/components/PageComponents/RoutComponent/${name}` )
        }
    }
}
</script>


<style lang="scss" scoped>
.rout-main-layout {
    padding-top: 144px;
    @media only screen and (max-width: 1440px) and (min-width: 769px) {
        padding-top: 89px
    }

    @media only screen and (max-width: 768px) {
        padding-top: 55px
    }
}
</style>
